import './main.css'
import React, { useState, useRef } from 'react';
// import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Plotly from 'plotly.js-dist'

//import LineChart from './components/Chart_comp'; // Assurez-vous que le chemin est correct
// import Header from './compon/header'

function Main() {
    const graph1Ref = useRef(null);
    const graph2Ref = useRef(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedENT, setSelectedENT] = useState('');
    const [selectedPronoteURL, setSelectedPronoteURL] = useState('');
    // const [imageLink, setImageLink] = useState('');
    const [moy_ecart, setmoy_ecart] = useState('');
    const [best_ecart_matiere, setbest_ecart_matiere] = useState('');
    const [worst_ecart_matiere, setworst_ecart_matiere] = useState('');
    const [best_ecart_ecart, setbest_ecart_ecart] = useState('');
    const [worst_ecart_ecart, setworst_ecart_ecart] = useState('');
    // const [score, setScore] = useState('');
    const [showLogin, setShowLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isErroring, setIsErroring] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [errorText, setErrorText] = useState(null);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleENTChange = (e) => {
        setSelectedENT(e.target.value);
    }

    const handlePronoteURLChange = (e) => {
        setSelectedPronoteURL(e.target.value);
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsLoading(true);

        const url = 'https://another-fcking-api.com/pronote';
        const data = {
            username: username,
            password: password,
            selectedENT: selectedENT,
            selectedPronoteURL: selectedPronoteURL,
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Spécifiez le type de contenu JSON
            },
            body: JSON.stringify(data), // Convertit les données en JSON
        };

        try {

            const response = await fetch(url, requestOptions);

            if (response.ok) {
                const responseData = await response.json(); // Parse la réponse JSON
                if (responseData.success) {
                    console.log(responseData)
                    setmoy_ecart(responseData.moy_ecart);
                    setbest_ecart_matiere(responseData.best_ecart_matiere);
                    setworst_ecart_matiere(responseData.worst_ecart_matiere);
                    setbest_ecart_ecart(responseData.best_ecart_ecart);
                    setworst_ecart_ecart(responseData.worst_ecart_ecart);
                    setShowLogin(false);
                    setIsLoading(false);
                    setIsErroring(false);

                    if (graph1Ref.current) {
                        var trace_moyenne_g = {
                            x: responseData.historical_moyenne_g_dates,
                            y: responseData.historical_moyenne_g,
                            type: 'scatter',
                            name: 'Moyenne élève'
                        };
                        var trace_moyenne_g_classe = {
                            x: responseData.historical_moyenne_g_dates,
                            y: responseData.historical_moyenne_g_classe,
                            type: 'scatter',
                            name: 'Moyenne classe (incomplète)'
                        };
                        
                        var layout_moyenne_g = {
                            showlegend: true,
                            legend: {
                                x: 1,
                                xanchor: 'right',
                                y: 1
                            },
                            font: {
                                family: 'Montserrat, sans-serif',
                                size: 12,
                                color: '#000000'
                              },
                            margin: { t: 60, r: 60, b: 60, l: 60 },
                            title: 'Évolution de la moyenne générale',
                            width:window.innerWidth * 0.7,
                            height:400,
                        };
                        
                        var graph_moyenne_g = [trace_moyenne_g, trace_moyenne_g_classe];
                        
                        Plotly.newPlot('graph1', graph_moyenne_g, layout_moyenne_g);
                        
                    };
                    
                    if (graph2Ref.current) {
                        var trace_ecarts = {
                            x: responseData.moy_ecarts_time_dates,
                            y: responseData.moy_ecarts_time,
                            type: 'scatter'
                        };
                        
                        var layout_ecarts = {
                            font: {
                                family: 'Montserrat, sans-serif',
                                size: 12,
                                color: '#000000'
                              },
                            margin: { t: 60, r: 60, b: 60, l: 60 },
                            title: 'Écart moyen à la classe (en %)',
                            width:window.innerWidth * 0.6,
                            height:400,
                        };
                        
                        var graph_ecarts = [trace_ecarts];
                        
                        Plotly.newPlot('graph2', graph_ecarts, layout_ecarts);
                    };
                } else {
                    console.log(responseData);
                    setIsErroring(false);
                }
            } else {
                setShowLogin(true);
                setIsLoading(false);
                setErrorCode(response.status);
                setErrorText(response.statusText);
                setIsErroring(true);
                console.log('Réponse incorrecte du serveur');
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <div className="main-container">
           <Helmet>
                <title>Not so pro (note)</title>
                <meta
                    property="og:title"
                    content="Scalping | Barbotine"
                />
            </Helmet>
                <img className='logo-image' alt='notsopronote-logo' src='https://media.discordapp.net/attachments/876447732259225612/1163074058599026748/NOT_SO_PRONOTE.png'></img>
            {showLogin ? (
                <div className='main-div'>
                    <h2>Se connecter</h2>
                    <form onSubmit={handleLogin}>
                    <div className='inputs-div' style={{ display:'flex', flexDirection:'column' }}>
                    <input
                        placeholder='Identifiant'
                        type="text"
                        id="id"
                        name="id"
                        required
                        minLength="3"
                        maxLength="30"
                        size="60"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                    <input
                        placeholder='Mot de passe'
                        type="password"
                        id="password"
                        name="password"
                        required
                        minLength="3"
                        maxLength="30"
                        size="60"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <input
                        placeholder="URL accueil de ton pronote"
                        type="text"
                        id="url_pronote"
                        name="url_pronote"
                        required
                        minLength="3"
                        maxLength="50"
                        size="60"
                        value={selectedPronoteURL}
                        onChange={handlePronoteURLChange}
                    />
                    <label style={{ display:'flex', flexDirection:'column', fontWeight:'500' }}>
                        Choisir l'ENT :
                        <select value={selectedENT} onChange={handleENTChange}>
                            <option value="ent_var">ENT Var</option>
                            <option value="ile_de_france">ENT Ile-de-France</option>
                            <option value="ent_somme">ENT Somme</option>
                            <option value="ent_hdf">ENT HDF</option>
                            <option value="ent_94">ENT 94</option>
                            <option value="ent_var">ENT Var</option>
                            <option value="lyceeconnecte_aquitaine">Lycée connecté Aquitaine</option>
                            <option value="paris_classe_numerique">Paris Classe Numérique (PCN)</option>
                            <option value="neoconnect_guadeloupe">Néoconnect Guadeloupe</option>
                            <option value="ent_mayotte">ENT Mayotte</option>
                            <option value="ent_essonne">ENT Essonne</option>
                            <option value="ent_ecollege78">ENT College 78</option>
                            <option value="ent77">ENT 77 (Seine-et-Marne)</option>
                            <option value="ent_creuse_educonnect">ENT Creuse Éduconnect</option>
                            <option value="occitanie_toulouse_edu">Occitanie Toulouse Éduconnect</option>
                            <option value="occitanie_montpellier_educonnect">Occitanie Montpellier Éduconnect</option>
                            <option value="laclasse_educonnect">La Classe Éduconnect</option>
                            <option value="ent_auvergnerhonealpe">ENT Auvergne Rhône-Alpes</option>
                            <option value="eclat_bfc">Éclat BFC</option>
                            <option value="cas_arsene76_edu">Cas Arsène76</option>
                            <option value="cas_seinesaintdenis_edu">Cas Seine-Saint-Denis Creuse</option>
                            <option value="cas_agora06">Cas Agora06</option>
                            <option value="ac_reunion">AC Réunion</option>
                            <option value="ac_poitiers">AC Poitiers Creuse</option>
                            <option value="ac_orleans_tours">AC Orléans Tours</option>
                            <option value="cas_cybercolleges42_edu">Cas Cyber-collège42</option>
                            <option value="ecollege_haute_garonne_edu">E-collège Haute-Garonne</option>
                            <option value="cas_kosmos">Cas Cosmos</option>
                            <option value="ac_reims">AC Reims</option>
                            <option value="occitanie_toulouse">Cas Occitanie-Toulouse</option>
                            <option value="occitanie_montpellier">Cas Occitanie-Montpellier</option>
                            <option value="l_normandie">L Normandie</option>
                            <option value="val_doise">Cas Val-D'oise</option>
                            <option value="lyceeconnecte_edu">Lycée connecté</option>
                            <option value="ent_elyco">ENT Elyco</option>
                            <option value="ent2d_bordeaux">ENT Argos Bordeaux</option>
                            <option value="atrium_sud">Atrium Sud</option>
                            <option value="laclasse_lyon">La Classe Lyon</option>
                            <option value="extranet_colleges_somme">Extranet College Somme</option>
                        </select>
                        </label>
                        <button
                        className='login-button'
                        type="submit"
                        disabled={isLoading}
                        style={{ display: isLoading ? 'none' : 'block' }}
                        >
                        Accéder aux données
                        </button>
                        <p className='error-paragraph' style={{ display: isErroring && !isLoading ? 'block' : 'none' }}>Erreur (code {errorCode || 'none'}: {errorText || 'none'})</p>
                        <img
                        src='https://media.tenor.com/On7kvXhzml4AAAAi/loading-gif.gif'
                        alt="Chargement"
                        style={{ display: isLoading ? 'block' : 'none', width:"30px", alignSelf:'center' }}
                        />
                    </div>
                </form>
                </div>
            ) : null}
            {(!showLogin && !isErroring && !isLoading) ? (
                // put grid
                <div className='main-div' style={{ width:'80%' }}>
                    <div className='data-div'>
                        <div className='case-graph1'>
                            <div className="graph" id='graph1' ref={graph1Ref}></div>
                        </div>
                        <div className='case-graph2'>
                            <div className="graph" id='graph2' ref={graph2Ref}></div>
                        </div>
                        <div className='case-text1'>
                            <p>Écart moyen par rapport à la classe : <strong>{moy_ecart} %</strong></p>
                            <p>Meilleur écart : <strong>{best_ecart_ecart} %</strong> (en {best_ecart_matiere})</p>
                            <p>Pire écart : <strong>{worst_ecart_ecart} %</strong> (en {worst_ecart_matiere})</p>
                        </div>
                        <div className='case-text2'>
                            <p>Écart moyen par rapport à la classe : <strong>{moy_ecart} %</strong></p>
                            <p>Meilleur écart : <strong>{best_ecart_ecart} %</strong> (en {best_ecart_matiere})</p>
                            <p>Pire écart : <strong>{worst_ecart_ecart} %</strong> (en {worst_ecart_matiere})</p>
                        </div>
                        
                    </div>
                </div>
            ) : 
                <div className='main-div' style={{ width:'80%', display:'none' }}>
                    <div className='data-div'>
                        {/* <img src={imageLink} alt='evolution' /> */}
                        <p>Écart moyen par rapport à la classe : <strong>{moy_ecart} %</strong></p>
                        <p>Meilleur écart : <strong>{best_ecart_ecart} %</strong> (en {best_ecart_matiere})</p>
                        <p>Pire écart : <strong>{worst_ecart_ecart} %</strong> (en {worst_ecart_matiere})</p>
                    </div>
                    <div className='charts-container'>
                        <div className="graph" id='graph1' ref={graph1Ref}>
                            {/* <LineChart className="graph" data={moyenne_g_list} labels={['Moyenne générale']} y_axis_label={"Note sur 20"} x_axis_label={'Date'} /> */}
                        </div>
                        <div className="graph" id='graph2' ref={graph2Ref}>
                            {/* <LineChart className="graph" data={ecarts_list} labels={['Écart moyen à la classe']} y_axis_label={"Écart en %"} x_axis_label={'Date'} /> */}
                        </div>
                    </div>
                </div>}
            <div className='main-div avertissement-div'>
                <h2>Avertissement</h2>
                <p className='avertissement-p'>Not so pronote n'est affilié en rien à Pronote ou Index Education. Les données personelles de l'identifiant et du mot de passe ne sont sauvegardés dans aucune base de données ni stockés sous aucune forme.</p>
            </div>
        </div>
    )
  }
  
export default Main